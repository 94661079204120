<template>
    <div>
        <NossosClientes/>
    </div>
</template>

<script>
import NossosClientes from '../components/nossos_clientes/nossosClientes.vue';
export default {
    components:{
        NossosClientes,
    }
}
</script>