<template>
  <div>
    <TopMenu />
    <div style="margin-top: 10vh; padding: 30px;">
      <div class="col text-center tracking-in-contract-bck">
        <div class="col title  mt-5">
          <h1 style="color:#f54308;" class="pb-5">NOSSOS CLIENTES</h1>
        </div>
        <div class="col p-5 quadro-imgs" >
          <img src="@/assets/unimed-andradas.png" alt="" class="img-logo p-3">
          <img src="@/assets/alfa.png" alt="" class="img-logo p-3">
          <img src="@/assets/Construai-Borges.png" alt="" class="img-logo p-3">
          <img src="@/assets/FABRICA OCULOS.png" alt="" class="img-logo p-3">
          <img src="@/assets/PLEOPLES.png" alt="" class="img-logo p-3">
          <img src="@/assets/redenção.png" alt="" class="img-logo p-3">
          <img src="@/assets/logo-americaidiomas.png" alt="" class="img-logo p-3">
          <img src="@/assets/logo serra azul.png" alt="" class="img-logo p-3">
          <img src="@/assets/HARPIA.png" alt="" class="img-logo p-3">
          <img src="@/assets/mattos.png" alt="" class="img-logo p-3">
          <img src="@/assets/ultra.png" alt="" class="img-logo p-3">
          <img src="@/assets/unipinhal.png" alt="" class="img-logo p-3">
          <img src="@/assets/uai.png" alt="" class="img-logo p-3">
          <img src="@/assets/boticario_andradas.png" alt="" class="img-logo p-3">
          <img src="@/assets/boticario_revendedor.png" alt="" class="img-logo p-3">
          <img src="@/assets/academia_funcional.png" alt="" class="img-logo p-3">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from "../menu/topMenu.vue";
export default {
  name: "NossosClientes",
  data() {
    return {};
  },
  methods: {},
  components: {
    TopMenu,
  },
};
</script>


<style scoped>
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
.twitter,
.facebook,
.linkedin,
.instagram {
  font-size: 16px;
  transition: all ease 0.5;
}
.twitter:hover,
.facebook:hover,
.linkedin:hover,
.instagram:hover {
  transition: all ease 0.5s;
  font-size: 18px;
  cursor: pointer;
}
.span-email {
  font-size: 16px;
  transition: all ease 0.5;
}
.span-email:hover {
  transition: all ease 0.5s;
  font-size: 18px;
  cursor: pointer;
}
.b-googlemaps {
  font-size: 16px;
  transition: all ease 0.5;
}
.b-googlemaps:hover {
  transition: all ease 0.5s;
  font-size: 18px;
  cursor: pointer;
}
.div-row {
  display: flex;
  flex-direction: row;
}
.quadro-imgs{
background-color: rgba(255, 255, 255, 0.692);
border-radius: 10px;

}
.img-logo{
  width: 150px;
  height: auto;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>